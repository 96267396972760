import moengage from '@moengage/web-sdk';
import {setPushUniqueUser} from './interface';

export const setUniqueId = (uniqueId: string) => {
  setPushUniqueUser(uniqueId);
  uniqueId ? moengage.add_unique_user_id(uniqueId) : moengage.destroy_session();
};

const showNotification = () => {
  moengage.call_web_push({
    soft_ask: true,
    main_class: 'moe-main-class',
    allow_class: 'moe-allow-class',
    block_class: 'moe-block-class',
  });
};

export const setupMoEngage = () => {
  moengage.initialize({
    app_id: '4EF8OD2M98DNI4TU5CNH1WU0',
    debug_logs: 0,
    cluster: 'DC_1',
    useLatest: true,
    swPath: '/serviceworker-push.js',
  });
  showNotification();
};

setupMoEngage();
